/* Initialisation du slider */
(function () {

    var sliders = document.querySelectorAll('.single-sit .th-slider-wrapper .th-slider:not(.no-autoload)');
    for (i = 0; i != sliders.length; i++) {
        var slider = sliders[i];
        initSlider(slider);
    }

    function initSlider(slider) {
        var parent = slider.parentNode;
        while (!parent.className.match(/th-slider-wrapper/)) {
            parent = parent.parentNode;
        }

        var navButtons = parent.querySelectorAll('.nav-buttons button');
        var dotsWrapper = parent.querySelectorAll('.nav-dots');

        var s = new thSlider(slider, {
            draggable: true,
            scrollListener: true,
            scrollModeMaxWidth: 980,
            oninit: function (slider) {

                slider.state.items.forEach(function (elem, index) {
                    if (elem.classList.contains('active')) {
                        slider.goto(index);
                    }
                })

                if (navButtons.length === 2) {
                    thSliderTools.onInitNav(slider, navButtons);
                }

                if (dotsWrapper) {
                    thSliderTools.onInitDots(slider, dotsWrapper[0]);
                }

            },
            onchange: function (slider) {

                if (navButtons.length > 0) {
                    thSliderTools.onChangeNavIgnoreLastItemIn(slider, navButtons);
                }
                if (dotsWrapper) {
                    thSliderTools.onChangeDots(slider, dotsWrapper[0]);
                }
            }
        });

    }

})();

/* Scroll down à la carte */
$("#carte-anchor").on('click', function (event) {
    if (this.hash !== "") {
        event.preventDefault();
        var hash = this.hash;

        $('html, body').animate({
            scrollTop: $(hash).offset().top
        }, 800, function () {
            window.location.hash = hash;
        });
    }
});

const map = document.querySelector(".carte-sit-iti");
const etapes = [...document.querySelectorAll(".card-sit-iti")];
const carteForm = document.querySelector("#overlay-carte-interactive-iti-form");

if (map) {
    const carteInteractiveITI = new CarteInteractiveIti(map, etapes, carteForm);
    carteInteractiveITI.init();
}