function initLogoSlider() {

    // Init des sliders
    var sliders = document.querySelectorAll('.bloc-partners .th-slider:not(.no-autoload)');
    sliders.forEach(function(slider) {
        initSlider(slider);
    });


    function initSlider(slider) {
        console.log('slider init');
        var navButtons = document.querySelectorAll('.nav-buttons button');

        var reverse = 0;
        if (slider.classList.contains('--reverse') && !isTabletPortraitOrSmalller()) {
            reverse = 1;
        }

        var s = new thSlider(slider, {
            draggable: true,
            rtl: reverse,
            scrollListener: true,
            oninit: function (slider) {
                if (navButtons.length === 2) {
                    thSliderTools.onInitNav(slider, navButtons);
                }
            },
            onchange: function (slider) {

                if (navButtons.length > 0) {
                    thSliderTools.onChangeNavIgnoreLastItemIn(slider, navButtons);
                }
            }
        });
    }
}

initLogoSlider();