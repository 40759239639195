class thListingMap {

    map = null;
    cards = {};
    markers = {};
    markerTargetCurrentGeo = null;
    bounds = [];
    cluster = null;
    popins = {};
    timeoutUpdate = null;
    markerBounds = null;
    isMobile = false;
    activeTrace = null;
    activeTraceID = null;
    btnTraces = null;
    allPointsRequestMade = false;
    mapLock = false;
    fullMapRequest = false;
    mapPoints = null;
    lastMapPoints = null;
    firstMapDraw = null;
    markerWidth = 300;
    markerIcon = 'default';

    options = {
        selector: null,
        breakpointMobile: 600,
        markerIcon: 'default',
        markerIconHover: 'hover',
        markerIconGeo: 'geoloc',
        markerWidth: 300,
        layersMaxZoom: 18,
        useCluster: false,
        onlyMobile: false,
        onlyDesktop: false,
        tracesGpx: false,
        listingsCards: '',
        desync: false
    };

    _selector = {
        btnSearchInZone: "#map-search-in-zone",
        btnSearchFull: "#map-search-full"
    }

    constructor(options) {
        options = $.extend(true, this.options, options);
        this.options = options;

        if (this.options.selector === null) {
            return;
        }

        if (document.body.clientWidth < this.options.breakpointMobile) {
            this.is_mobile = true;
        }

        let initmap = false;

        // on veut sur mobile et desktop du coup on se moque de savoir si on es sur mobile
        if (!this.options.onlyMobile && !this.options.onlyDesktop) {
            initmap = true;
        } else {
            if (this.is_mobile && this.options.onlyMobile) {
                initmap = true;
            } else if (!this.is_mobile) {
                initmap = true;
            }
        }

        if (initmap) {
            this.initMap();
        }
    }

    initMap() {
        if (document.querySelector(this.options.selector)) {
            this.map = th_maps.initSingleMap(document.querySelector(this.options.selector));
        }
    }

    setCards($cards) {
        if (!this.map || this.options.desync) {
            return;
        }

        const that = this;
        const cards = {};

        $cards.each(function () {
            const lat = this.getAttribute('data-lat') * 1;
            const lng = this.getAttribute('data-lng') * 1;

            if (lat !== 0 && lng !== 0) {

                var card = {
                    id: this.getAttribute('data-id'),
                    lat: lat,
                    lng: lng,
                    html: this.outerHTML.replace('data-src', 'src').replace('lazy', ''),
                    el: this
                };

                cards[this.getAttribute('data-id')] = card;

                $(this).on('mouseover', function () {
                    that.markers[card.id].setIcon(th_maps.markersIcons[that.options.markerIconHover]);
                })
                $(this).on('mouseout', function () {
                    that.markers[card.id].setIcon(th_maps.markersIcons[that.options.markerIcon]);
                })
            }
        });

        delete cards.null;

        this.cards = cards;
    }

    update() {
        if (!this.map) {
            return;
        }

        this.emptyMap();

        var cards = this.cards;

        clearTimeout(this.timeoutUpdate);
        if (!this.map) {
            setTimeout(() => {
                this.update(cards);
            }, 500);
            return false;
        }

        if (this.isMobile) {
            fm._obj.mobileSliderManager = new mapSliderMarkerMobile(fm._map.map);
            fm._obj.mobileSliderManager.setIndexDataName('id');
            fm._obj.mobileSliderManager.init($('#results-listing'));
            fm._map.map = fm._obj.mobileSliderManager.map;
            fm._map.markerBounds = fm._obj.mobileSliderManager.markerBounds;
        } else {
            if (this.options.desync) {
                this.initDesyncedMap();
            } else {
                this.map._layersMaxZoom = this.options.layersMaxZoom;

                if (!this.options.useCluster) {
                    $.each(cards, (k, card) => {
                        const marker = this.getMarker(card, this.map);

                        this.map.addLayer(marker);
                        this.bounds.push(marker.getLatLng());
                    });

                    this.markerBounds = L.latLngBounds(this.bounds);
                } else {
                    this.cluster = L.markerClusterGroup({
                        showCoverageOnHover: false,
                        maxClusterRadius: function (mapZoom) {
                            if (mapZoom > 9) {
                                return 20;
                            } else {
                                return 80;
                            }
                        },
                    });

                    $.each(cards, (k, card) => {
                        this.cluster.addLayer(this.getMarker(card, false));
                    });

                    this.map.addLayer(this.cluster);
                }

                setTimeout(() => {
                    this.fitMap();
                }, 250);

                if (this.options.tracesGpx) {
                    this.initTracesGpx();
                }
            }
        }
    }

    emptyMap() {
        if (this.options.desync) {
            return;
        }

        if (this.options.useCluster) {
            this.hideCluster();
        } else if (Object.keys(this.markers).length > 0) {
            $.each(this.markers, (k, marker) => {
                this.map.removeLayer(marker);
            })
            this.markers.bounds = [];
        }

        if (this.options.tracesGpx && (this.activeTrace || this.activeTraceID)) {
            if (this.btnTraces) {
                this.btnTraces.forEach(btnTrace => {
                    if (btnTrace.classList.contains("is-active")) {
                        btnTrace.classList.remove("is-active")
                    }
                });
            }

            this.map.removeLayer(this.activeTrace);
            this.activeTrace = null;
            this.activeTraceID = null;
        }
    }

    fitMap() {
        if (this.map) {
            if (this.options.desync) {
                return;
            }

            this.map.invalidateSize();

            if (!this.options.useCluster) {
                if (typeof this.markerBounds._southWest !== 'undefined') {
                    this.map.fitBounds(this.markerBounds, {padding: [25, 25]});
                }
            } else {
                const cluster_bounds = this.cluster.getBounds();
                if (typeof cluster_bounds._southWest !== 'undefined') {
                    this.map.fitBounds(cluster_bounds, {padding: [25, 25]});
                }
            }

            if (this.map.getZoom() >= thConfig.map.init.tileLayerOptions.maxZoom) {
                this.map.setZoom(15);
            }
        }
    }

    getMarker(card, map) {
        if (!this.markers[card.id]) {
            this.createMarker(card, map);
        }
        return this.markers[card.id];
    }

    createMarker(card, map) {
        this.markers[card.id] = th_maps.createMarker(map, {lat: card.lat, lng: card.lng}, this.options.markerIcon);

        this.markers[card.id].on('click', () => {
            if (card.el) {
                const btn = card.el.querySelector('.btn-trace');

                if (btn) {
                    $('html, body').animate({scrollTop: $(card.el).offset().top - 150}, th_ancres.scrollSpeed);

                    this.addTraceGpx(btn);
                }
            }
        });

        this.markers[card.id].addEventListener('mouseover', () => {
            this.markers[card.id].setIcon(th_maps.markersIcons[this.options.markerIconHover]);

            if (card.el && !card.el.classList.contains('is-hover')) {
                card.el.classList.add('is-hover');
            }
        });

        this.markers[card.id].addEventListener('mouseout', () => {
            this.markers[card.id].setIcon(th_maps.markersIcons[this.options.markerIcon]);

            if (card.el && card.el.classList.contains('is-hover')) {
                card.el.classList.remove('is-hover');
            }
        });

        this.bounds.push(this.markers[card.id].getLatLng());

        let card_html = '';

        if (!this.isMobile) {
            card_html = '<div class="card-wrapper">' + card.html + '</div>';
            card_html = card_html.replace('src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7', '');
            this.popins[card.id] = th_maps.createInfoWindow(card_html, this.markers[card.id], this.markerWidth);
        }

    }

    hideCluster() {
        if (!this.map) {
            return null;
        }

        if (this.cluster) {
            this.map.removeLayer(this.cluster);
        }
    }

    initTracesGpx() {
        const listingsCards = document.querySelector(this.options.listingsCards);

        if (listingsCards) {
            this.btnTraces = [...listingsCards.querySelectorAll(".btn-trace")];

            if (this.btnTraces.length) {
                this.btnTraces.forEach(btn => {
                    btn.addEventListener("click", e => {
                        e.preventDefault();
                        e.stopPropagation();

                        this.addTraceGpx(e.target);
                    })
                })
            }
        }
    }

    addTraceGpx(el) {
        var _self = this;

        if (!el.classList.contains("is-loading")) {
            this.btnTraces.forEach(btnTrace => {
                btnTrace.classList.remove("is-active");
                btnTrace.classList.remove("is-loading");
            });

            const id = parseInt(el.getAttribute("data-id"), 10);

            if (id) {
                if (this.activeTraceID && this.activeTraceID === id && this.activeTrace) {
                    this.map.removeLayer(this.activeTrace);
                    this.activeTrace = null;
                    this.activeTraceID = null;

                    this.fitMap();
                    el.classList.remove("is-active");
                } else {
                    el.classList.add("is-loading");

                    const data = new FormData();
                    data.append("action", "get_sit_trace");
                    data.append("id", id);

                    this.activeTraceID = id;

                    fetch("/wp-admin/admin-ajax.php", {
                        method: "POST",
                        body: data
                    })
                        .then(response => response.json())
                        .then(response => {
                            if (response.success && response.data.trace && response.data.type) {
                                const highlightStyle = {
                                    fillColor: thConfig.map.kmlBgColor,
                                    weight: thConfig.map.kmlWeight,
                                    opacity: thConfig.map.kmlOpacity,
                                    color: thConfig.map.kmlColorIti,
                                    fillOpacity: thConfig.map.kmlBgOpacity
                                };

                                omnivore[response.data.type](response.data.trace).on("ready", function (e) {
                                    if (_self.activeTrace) {
                                        _self.map.removeLayer(_self.activeTrace);
                                    }

                                    _self.activeTrace = this;

                                    this.setStyle(highlightStyle);
                                    _self.map.fitBounds(this.getBounds());
                                })
                                    .addTo(this.map);
                            }

                            el.classList.remove("is-loading");
                            el.classList.add("is-active");
                        })
                }
            }
        }
    }

    createGeoMarker(lat = null, lng = null) {
        if (!lat && !lng) {
            const inputlat = document.querySelector("input[name='geoloc_lat']");
            const inputlng = document.querySelector("input[name='geoloc_lng']");
            lat = inputlat.value;
            lng = inputlng.value;
        }

        if (!lat && !lng) {
            const centermap = this.map.getCenter();
            lat = centermap.lat;
            lng = centermap.lng;
        }

        this.markerTargetCurrentGeo = th_maps.createMarker(this.map, {
            lat: lat,
            lng: lng
        }, this.options.markerIconGeo);
    }

    showGeoMarker(lat, lng) {
        if (this.map) {
            if (!this.markerTargetCurrentGeo) {
                this.createGeoMarker(lat, lng)
            }

            const newLatLng = new L.LatLng(lat, lng);
            this.markerTargetCurrentGeo.setLatLng(newLatLng);
            this.map.addLayer(this.markerTargetCurrentGeo);
            this.map.panTo(newLatLng);
        }
    }

    hideGeoMarker() {
        if (this.map) {
            if (this.map.hasLayer(this.markerTargetCurrentGeo)) {
                this.map.removeLayer(this.markerTargetCurrentGeo);
            }
        }
    }

    initDesyncedMap() {
        if (this.map._container.getAttribute("data-geo-bounds") !== "1") {
            this.options.desync = false;

            this.setCards($(this.options.selectors.cards, this.options.selectors.cardsContainer));
            return this.update();
        }

        if (!this.allPointsRequestMade && (this.map._container.getAttribute("data-all-points") === "1" || this.fullMapRequest)) {
            this.mapLock = true;

            this.fetchAllPoints().then(response => {
                if (response && response.success) {
                    this.mapPoints = response.data;
                    this.drawPointsOnMap(response.data);

                    this.allPointsRequestMade = true;

                    if (this.fullMapRequest) {
                        this.hideBtnSearchFull();
                        this.fullMapRequest = false;
                    }

                    setTimeout(() => {
                        this.mapLock = false;
                    }, 1500);
                }
            });
        } else if (this.map._container.getAttribute("data-geo-bounds") === "1") {
            if (!this.fullMapRequest) {
                // Si on a pas encore
                if (!this.mapPoints) {
                    this.mapPoints = JSON.parse(this.map._container.getAttribute("data-map-points"));
                }
                
                // Pour prevent un redraw entier de la carte, on check si le lastMapPoints est bien différent de celui actuel
                if (this.mapPoints !== this.lastMapPoints) {
                    this.drawPointsOnMap(this.mapPoints);
                    this.lastMapPoints = this.mapPoints;

                    setTimeout(() => {
                        this.mapLock = false;
                    }, 1500);
                }
            }
        }

        if (!this.eventsInit) {
            this.listenToMapDrag();
            this.listenToMapZoom();
            this.btnSearchInZone();
            this.btnSearchFull();

            this.eventsInit = true;
        }

        if (this.mapHasValues()) {
            this.displayBtnSearchFull();
        }

        setTimeout(() => {
            this.mapLock = false;
        }, 1500);
    }

    btnSearchInZone() {
        const el = document.querySelector(this._selector.btnSearchInZone);

        if (el) {
            el.addEventListener("click", e => {
                e.preventDefault();

                this.hideBtnSearchFull();
                this.hideBtnSearchInZone();

                // D'abord, on lock la map
                this.mapLock = true;

                // Puis, on récupère les coordonnées de la map pour set toutes les inputs à leur bonne valeurs
                const NE = this.map.getBounds().getNorthEast();
                const SW = this.map.getBounds().getSouthWest();
                const zoom = this.map.getZoom();
                const center = this.map.getCenter();
                this.setMapValues(center.lat, center.lng, zoom, NE.lat, NE.lng, SW.lat, SW.lng);

                document.dispatchEvent(new Event("thuria-submit-listing"));
            });
        }
    }

    btnSearchFull() {
        const el = document.querySelector(this._selector.btnSearchFull);

        if (el) {
            el.addEventListener("click", e => {
                e.preventDefault();

                this.hideBtnSearchFull();
                this.hideBtnSearchInZone();
                this.cleanMapValues();
                // this.form.resetFormValues();

                this.mapLock = true;
                this.fullMapRequest = true;
                this.allPointsRequestMade = false;

                document.dispatchEvent(new Event("thuria-submit-listing"));
            });
        }
    }

    displayBtnSearchInZone() {
        const el = document.querySelector(this._selector.btnSearchInZone);

        if (el && !el.classList.contains("is-active")) {
            el.classList.add("active");
        }
    }

    hideBtnSearchInZone() {
        const el = document.querySelector(this._selector.btnSearchInZone);

        if (el && el.classList.contains("is-active")) {
            el.classList.remove("active");
        }
    }

    displayBtnSearchFull() {
        const el = document.querySelector(this._selector.btnSearchFull);

        if (el && !el.classList.contains("is-active")) {
            el.classList.add("active");
        }
    }

    hideBtnSearchFull() {
        const el = document.querySelector(this._selector.btnSearchFull);

        if (el && el.classList.contains("is-active")) {
            el.classList.remove("active");
        }
    }

    listenToMapDrag() {
        this.map.addEventListener("moveend", () => {
            if (!this.mapLock) {
                this.displayBtnSearchInZone();
            }
        });
    }

    listenToMapZoom() {
        this.map.addEventListener("zoomend", () => {
            if (!this.mapLock) {
                this.displayBtnSearchInZone();
            }
        });
    }

    setMapValues(centerlat, centerlng, zoom, neLat, neLng, swLat, swLng) {
        document.querySelector("input[name='centerlat']").value = centerlat;
        document.querySelector("input[name='centerlng']").value = centerlng;
        document.querySelector("input[name='zoom']").value = zoom;
        document.querySelector("input[name='ne_lat']").value = neLat;
        document.querySelector("input[name='ne_lng']").value = neLng;
        document.querySelector("input[name='sw_lat']").value = swLat;
        document.querySelector("input[name='sw_lng']").value = swLng;
    }

    cleanMapValues() {
        document.querySelector("input[name='centerlat']").value = null;
        document.querySelector("input[name='centerlng']").value = null;
        document.querySelector("input[name='zoom']").value = null;
        document.querySelector("input[name='ne_lat']").value = null;
        document.querySelector("input[name='ne_lng']").value = null;
        document.querySelector("input[name='sw_lat']").value = null;
        document.querySelector("input[name='sw_lng']").value = null;
    }

    mapHasValues() {
        if (document.querySelector("input[name='centerlat']").value
            && document.querySelector("input[name='centerlng']").value
            && document.querySelector("input[name='zoom']").value
            && document.querySelector("input[name='ne_lat']").value
            && document.querySelector("input[name='ne_lng']").value
            && document.querySelector("input[name='sw_lat']").value
            && document.querySelector("input[name='sw_lng']").value) {
            return true;
        }

        return false;
    }

    async fetchAllPoints() {
        const data = new FormData();
        data.append("action", "get_points");
        data.append("post_id", this.map._container.getAttribute("data-listing-id"));

        return fetch("/wp-admin/admin-ajax.php", {
            method: "POST",
            body: data
        }).then(response => response.json());
    }

    initEventsOnPoints(marker, id) {
        // On check d'abord si l'infowindow existe
        if (!this.popins[id]) {
            var fiche = document.querySelector(this._selector.cardsContainer + " [data-id='" + id + "']");

            if (fiche) {
                var card_html = fiche.outerHTML;
                card_html = card_html.replace('src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"', '');
                this.popins[id] = th_maps.createInfoWindow(card_html, marker, this.markerWidth);
                var lazyLoadInstance = new LazyLoad({
                    elements_selector: ".lazy"
                });
            } else {
                marker.addEventListener("click", () => {
                    if (!this.popins[id]) {
                        const data = new FormData();
                        data.append("action", "get_single_point");
                        data.append("id", id);

                        fetch("/wp-admin/admin-ajax.php", {
                            body: data,
                            method: "POST",
                        })
                            .then(response => response.json())
                            .then((response) => {
                                if (response && response.data) {
                                    card_html = response.data.card_html;
                                    card_html = card_html.replace('src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"', '');

                                    this.popins[id] = th_maps.createInfoWindow(card_html, marker, this.markerWidth);
                                    marker.openPopup();
                                    var lazyLoadInstance = new LazyLoad({
                                        elements_selector: ".lazy"
                                    });
                                }
                            })
                    }
                })
            }
        }
    }

    drawPointsOnMap(data) {
        this.hideCluster();
        this.cluster = L.markerClusterGroup({showCoverageOnHover: false});

        $.each(data, (index, value) => {
            this.markers[value.id] = th_maps.createMarker(null, {
                lat: value.lat,
                lng: value.lng
            }, this.markerIcon);
            this.cluster.addLayer(this.markers[value.id]);
            this.markers[value.id].id = value.id;

            this.initEventsOnPoints(this.markers[value.id], value.id);
        });

        this.map.addLayer(this.cluster);
        this.lastMapPoints = data;

        setTimeout(() => {
            this.map.invalidateSize();
            this.map.fitBounds(this.cluster.getBounds(), {padding: [40, 40]});
        }, 250);
    }
}


