th_maps.onLoad(function () {
    th_maps.addMarkerIcon('default', {
        iconUrl: '' + document.location.origin + '/wp-content/themes/saumur/assets/images/markers/ico-marker-map-2x.png',
        shadowUrl: null,
        iconSize: [26, 26],
        iconAnchor: [13, 13]
    });

    th_maps.addMarkerIcon('hover', {
        iconUrl: '' + document.location.origin + '/wp-content/themes/saumur/assets/images/markers/ico-marker-map-hover-2x.png',
        shadowUrl: null,
        iconSize: [32, 32],
        iconAnchor: [16, 16]
    });

    th_maps.addMarkerIcon('depart', {
        iconUrl: '' + document.location.origin + '/wp-content/themes/saumur/assets/images/markers/ico-marker-map-depart-2x.png',
        shadowUrl: null,
        iconSize: [40, 40],
        iconAnchor: [20, 20]
    });

});

th_maps.init(thConfig.map.init);