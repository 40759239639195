var resaInput = $('#overlay-reservation input[name="reservation"]');

resaInput.on('change',function(){
    console.log(this);
    if($(this).is(':checked')){
        var id = $(this).attr('id');

        $('.onglets__labels label').removeClass('current');
        $('label[for="'+id+'"]').addClass('current');
    }
});