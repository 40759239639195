
/* Initialisation du slider */
(function () {

    function initBloc(bloc) {

        // empèche le scroll en haut de page au click sur le label
        var onglets = bloc.querySelectorAll('label');
        onglets.forEach(function(onglet) {
            onglet.addEventListener('click', onClickOnglet);
        });

        // Désactive le click sur le label et déclenche un click manuellement sur bouton radio correspondant
        function onClickOnglet(e) {
            e.preventDefault();
            var target = e.currentTarget;
            var radioId = target.getAttribute('for');
            var radio = bloc.querySelector('#'+radioId);
            radio.click();

            // target.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
        }


        // Init des sliders
        var sliders = bloc.querySelectorAll('.th-slider:not(.no-autoload)');
        sliders.forEach(function(slider) {
            initSlider(slider);
        });


        function initSlider(slider) {
            var navButtons = bloc.querySelectorAll('.nav-buttons button');

            var reverse = 0;
            if (slider.classList.contains('--reverse') && !isTabletPortraitOrSmalller()) {
                reverse = 1;
            }

            var s = new thSlider(slider, {
                draggable: true,
                rtl: reverse,
                scrollListener: true,
                oninit: function (slider) {
                    if (navButtons.length === 2) {
                        thSliderTools.onInitNav(slider, navButtons);
                    }
                },
                onchange: function (slider) {

                    if (navButtons.length > 0) {
                        thSliderTools.onChangeNavIgnoreLastItemIn(slider, navButtons);
                    }
                }
            });
        }
    }





    // Initialisation de tous les blocs concernés de la page
    var blocs = document.querySelectorAll('.bloc-relation');
    blocs.forEach(function (bloc) {
        initBloc(bloc);
    });

})();