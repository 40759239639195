class CarteInteractiveIti {
    mapContainer;
    map;
    etapes;
    form;
    initialTrace;
    activeTrace;
    activeTraceID;
    activeMarkerGroups = [];
    type;
    traces;
    highlightStyle = {
        fillColor: "#0B3C43",
        weight: thConfig.map.kmlWeight,
        opacity: thConfig.map.kmlOpacity,
        color: "#0B3C43",
        fillOpacity: thConfig.map.kmlBgOpacity
    };

    constructor(mapContainer, etapes, form) {
        this.mapContainer = mapContainer;
        this.etapes = etapes;
        this.form = form;
    }

    init() {
        this.initMap();
        this.initEtapes();
        this.initFiltres();
        this.initChangeTraces();
    }

    initMap() {
        const mapContainer = this.mapContainer.querySelector(".maps");
        this.map = th_maps.initSingleMap(mapContainer);

        if (typeof carteInteractiveTracesGpx !== "undefined" && carteInteractiveTracesGpx) {
            this.traces = carteInteractiveTracesGpx;
            this.type = "gpx";
        } else if (typeof carteInteractiveTracesKml !== "undefined" && carteInteractiveTracesKml) {
            this.traces = carteInteractiveTracesKml;
            this.type = "kml";
        }

        if (this.traces && this.traces.length) {
            this.setTrace(
                this.traces[0].url,
                this.type,
                (trace) => {
                    this.initialTrace = trace;
                }
            )
        }

        const btnOpenMap = this.mapContainer.querySelector("#open-map");
        const btnOpenMapFiltres = this.mapContainer.querySelector("#open-filtres");
        const btnCloseMap = this.mapContainer.querySelector("#close-map");

        if (btnOpenMap) {
            btnOpenMap.addEventListener("click", this.openMap.bind(this));
        }

        if (btnOpenMapFiltres) {
            btnOpenMapFiltres.addEventListener("click", this.openMap.bind(this));
        }

        if (btnCloseMap) {
            btnCloseMap.addEventListener("click", this.closeMap.bind(this));
        }

        this.loadPoints();
    }

    initEtapes() {
        if (this.etapes.length) {
            this.etapes.forEach((el) => {
                const btnTrace = el.querySelector(".btn-trace");

                if (btnTrace) {
                    btnTrace.addEventListener("click", e => {
                        e.preventDefault();
                        e.stopPropagation();

                        if (!e.target.classList.contains("is-loading")) {
                            this.etapes.forEach(btnTrace => {
                                btnTrace.classList.remove("is-active");
                                btnTrace.classList.remove("is-loading");
                            });

                            const id = parseInt(e.target.getAttribute("data-id"), 10);

                            if (id) {
                                if (this.activeTraceID && this.activeTraceID === id && this.activeTrace) {
                                    this.removeTrace();
                                    this.activeTraceID = null;

                                    e.target.classList.remove("is-active");

                                    if (this.initialTrace) {
                                        this.map.addLayer(this.initialTrace);
                                        this.fitMap();
                                    }
                                } else {
                                    e.target.classList.add("is-loading");

                                    const data = new FormData();
                                    data.append("action", "get_sit_trace");
                                    data.append("id", id);

                                    this.activeTraceID = id;

                                    fetch("/wp-admin/admin-ajax.php", {
                                        method: "POST",
                                        body: data
                                    })
                                        .then(response => response.json())
                                        .then(response => {
                                            if (response.success && response.data.trace && response.data.type) {
                                                this.setTrace(response.data.trace, response.data.type);
                                            }

                                            e.target.classList.remove("is-loading");
                                            e.target.classList.add("is-active");
                                        })
                                }
                            }
                        }
                    })
                }
            })
        }
    }

    loadPoints() {
        const _self = this;
        thCarteInteractiveITIPoints.forEach(selection => {
            const selectionId = selection.selection_id;
            let icon = null;

            if (selection.icon.url) {
                icon = "icon-" + selectionId;

                th_maps.addMarkerIcon(icon, {
                    iconUrl: selection.icon.url,
                    shadowUrl: null,
                    iconSize: [25, 25],
                    iconAnchor: [13, 13]
                });
            }

            selection.points.forEach(point => {
                const marker = th_maps.createMarker(null, {
                    lat: point.lat,
                    lng: point.lng
                }, icon ? icon : 'default', 'selection-carte-' + selectionId);
                const lat = point.lat;
                const lng = point.lng;
                const post_id = point.id;

                marker.on('click', function () {
                    if (_self.lastActiveMarker) {
                        if (icon) {
                            _self.lastActiveMarker.setIcon(th_maps.markersIcons[icon]);
                        } else {
                            _self.lastActiveMarker.setIcon(th_maps.markersIcons['default']);
                        }
                        _self.lastActiveMarker = null;
                    }
                    marker.setIcon(th_maps.markersIcons['hover']);
                    _self.lastActiveMarker = marker;

                    const formData = new FormData();
                    formData.append("action", "get_carte_interactive_point");
                    formData.append("post_id", post_id);

                    fetch("/wp-admin/admin-ajax.php", {
                        method: "POST",
                        body: formData
                    })
                        .then(response => response.json())
                        .then(response => {
                            const infoWindow = th_maps.createInfoWindow(response.html);

                            infoWindow.on('remove', function () {
                                if (icon) {
                                    _self.lastActiveMarker.setIcon(th_maps.markersIcons[icon]);
                                } else {
                                    _self.lastActiveMarker.setIcon(th_maps.markersIcons['default']);
                                }
                            });

                            infoWindow.setLatLng({lat: lat, lng: lng}).openOn(_self.map);

                            var lazyLoadInstance = new LazyLoad({
                                elements_selector: ".lazy"
                            });
                        })
                })

            });
        });

        this.form.addEventListener("submit", function (e) {
            e.preventDefault();

            th_overlay.close("overlay-carte-interactive-iti");
        });
    }

    initFiltres() {
        // accordeon des filtres
        [...this.form.querySelectorAll(".group-checkbox > legend")].forEach(el => {
            if (el.parentNode.classList.contains("is-active")) {
                el.parentNode.classList.remove("is-active");
            } else {
                el.parentNode.classList.add("is-active");
            }

            el.addEventListener("click", () => {
                if (el.parentNode.classList.contains("is-active")) {
                    el.parentNode.classList.remove("is-active");
                } else {
                    el.parentNode.classList.add("is-active");
                }
            });
        });

        [...this.form.querySelectorAll(".frm_checkbox > label")].forEach(el => {
            const span = document.createElement("span");

            el.appendChild(span);
        });

        const inputs = [...this.form.querySelectorAll("input[type='checkbox']")];

        if (inputs) {
            inputs.forEach(input => {
                input.addEventListener("change", () => {
                    const inputValue = 'selection-carte-' + input.value;

                    if (input.checked) {
                        th_maps.showGroupMarker(inputValue, this.map);

                        if (!this.activeMarkerGroups.includes(inputValue)) {
                            this.activeMarkerGroups.push(inputValue);
                        }
                    } else {
                        th_maps.hideGroupMarker(inputValue);

                        if (this.activeMarkerGroups.includes(inputValue)) {
                            this.activeMarkerGroups = this.activeMarkerGroups.filter(e => e !== inputValue);
                        }
                    }

                    this.fitMap();
                });
            });
        }
    }

    initChangeTraces() {
        const traceSelect = $("#carte-interactive-select-traces");

        if (traceSelect) {
            traceSelect.selectric({
                optionsItemBuilder: function (itemData, element, index) {
                    let color = null;
                    const option = $('option', element).get(index);

                    if (option) {
                        color = option.getAttribute("data-color");
                    }

                    return color ? `<span style="background: ${color}" class="select-color"></span>${itemData.text}` : itemData.text;
                }
            });

            traceSelect.on("change", () => {
                this.setTrace(traceSelect.val(), this.type);
            });
        }
    }

    setTrace(url, type, callback) {
        const _self = this;
        this.removeTrace();

        this.traces.forEach(trace => {
            if (trace.url === url) {
                this.setTraceBtn(trace.url);

                omnivore[type](url).on("ready", function () {
                    _self.activeTrace = this;
                    const style = _self.highlightStyle;

                    if (trace.color && _self.traces.length > 1) {
                        style.color = trace.color;
                        style.fillColor = trace.color;
                    }

                    this.setStyle(style);
                    _self.map.fitBounds(this.getBounds());

                    if (callback) {
                        callback(this);
                    }
                })
                    .addTo(this.map);
            }
        })
    }

    setTraceBtn(url) {
        const btnContainer = document.querySelector(".js-carte-interactive-gpx-download");

        if (btnContainer) {
            btnContainer.setAttribute("href", url);
        }
    }

    removeTrace() {
        if (this.activeTrace) {
            this.map.removeLayer(this.activeTrace);
        }

        this.activeTrace = null;
    }

    openMap(e) {
        e.preventDefault();

        if (!this.mapContainer.querySelector(".carte-sit-iti__btns").classList.contains("is-hidden")) {
            this.mapContainer.querySelector(".carte-sit-iti__btns").classList.add("is-hidden");
        }

        if (!this.mapContainer.classList.contains("is-fullscreen")) {
            this.mapContainer.classList.add("is-fullscreen");

            this.map.invalidateSize();
        }
    }

    closeMap(e) {
        e.preventDefault();

        if (this.mapContainer.querySelector(".carte-sit-iti__btns").classList.contains("is-hidden")) {
            this.mapContainer.querySelector(".carte-sit-iti__btns").classList.remove("is-hidden");
        }

        if (this.mapContainer.classList.contains("is-fullscreen")) {
            this.mapContainer.classList.remove("is-fullscreen");

            this.map.invalidateSize();
        }
    }

    fitMap() {
        if (this.activeMarkerGroups.length) {
            const bounds = L.latLngBounds();

            this.activeMarkerGroups.forEach(markerGroup => {
                if (th_maps.markersGrouped[markerGroup]) {
                    th_maps.markersGrouped[markerGroup].forEach(marker => {
                        bounds.extend(marker.getLatLng());
                    });
                }
            });

            this.map.fitBounds(bounds);
        } else if (this.activeTrace) {
            this.map.fitBounds(this.activeTrace.getBounds());
        } else if (this.initialTrace) {
            this.map.fitBounds(this.initialTrace.getBounds());
        }
    }
}