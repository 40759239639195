th_maps.onLoad(function () {
    if (document.body.clientWidth <= thConfig.mobileBreakpoint) {
        th_maps.addMarkerIcon('geoloc', {
            iconUrl: '' + template_path + '/components/SITGeoloc/assets/img/geoloc2x.png',
            shadowUrl: null,
            iconSize: [26, 26],
            iconAnchor: [13, 13]
        });
    }
});
