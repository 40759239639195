(function(){

    /*
        ! ATTENTION !
        Ce slider est présent une seul fois dans la page
        Ce code n'est pas utilisable pour du bloc
    */

    var sliderEl = document.querySelector('.cover-home .th-slider');

    if(sliderEl){

        var navContainerEl = document.querySelector('.cover-home .nav-buttons');
        var dotsContainerEl = document.querySelector('.cover-home .nav-dots');


        var s = new thSlider(sliderEl, {
            draggable: true,
            scrollListener: true,
            scrollModeMaxWidth: 1024,

            oninit: function (slider) {
                if (navContainerEl) {
                    thSliderTools.onInitNav(slider, navContainerEl.children);
                }
                if (dotsContainerEl) {
                    // Ajout des numéros sur les dots
                    thSliderTools.onInitDots(slider, dotsContainerEl, function(dotEl, index){
                        var num = (parseInt(index) < 9) ? '0' + (parseInt(index)+1) : (parseInt(index)+1);
                        dotEl.innerHTML = num;
                        return dotEl;
                    });
                }

//***
                if(navContainerEl){
                    thSliderTools.onChangeNavIgnoreLastItemIn(slider, navContainerEl.children);
                }
                if (dotsContainerEl) {
                    thSliderTools.onChangeDots(slider, dotsContainerEl);
                }

            },

            beforechange: function (slider) {
                if(navContainerEl){
                    thSliderTools.onChangeNavIgnoreLastItemIn(slider, navContainerEl.children);
                }
                if (dotsContainerEl) {
                    thSliderTools.onChangeDots(slider, dotsContainerEl);
                    dotsContainerEl.blur();

                    // suppression du focus sur le dot courant
                    var buttons = dotsContainerEl.querySelectorAll('button');
                    buttons.forEach(function (el) {
                        if(document.activeElement == el) {
                            el.blur();
                        }
                    });
                }
            },


            /*onchange: function (slider) {
                if(navContainerEl){
                    thSliderTools.onChangeNavIgnoreLastItemIn(slider, navContainerEl.children);
                }
                if (dotsContainerEl) {
                    thSliderTools.onChangeDots(slider, dotsContainerEl);
                }
            }*/
        });

    }

})();